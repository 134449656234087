/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useCart } from "../components/Cart/cartContext";
import { PaystackButton } from "react-paystack";
import "../styles/checkout.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Country, State } from "country-state-city";
import Payment from "../assets/image/payment.png";
import PaymentImg from "../assets/image/newpaycard.png";
import PaymentApproved from "../components/Receipt/receipt";

const Checkout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bankBtn, setBankBtn] = useState(false);
  const { cart, setCart, selectedSize } = useCart();
  const [userDetails, setUserDetails] = useState({
    email: "",
    deliveryLocation: "",
    address: "",
    phoneNumber: "",
    fullName: "",
  });
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(true);
  const [showBankModal, setShowBankModal] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [shippingFee, setShippingFee] = useState(0);
  const [postalCode, setPostalCode] = useState("");
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);

  useState("no");

  const ConfirmPaymentModal = ({ onClose, onPaymentMade }) => {
    return (
      <div className="paymentOverlay">
        <div className="Confirmmodal">
          <h2>Bank Transfer Instructions</h2>
          <p>
            Please make a bank transfer to the following account:
            <br />
            Name: DON-T BRAG LUXURY
            <br />
            Account Number: 8137816749
            <br />
            Bank Name: MONIEPOINT MFB
          </p>
          <p>
            After making the payment, please share the receipt via WhatsApp to
            +234 813 7816 749
          </p>
          <div className="btnModaldiv">
            <button onClick={onPaymentMade} className="btnModal">
              I've Made the Payment
            </button>
            <button onClick={onClose} className="btnModal">
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleShowModal = () => {
    setVisible(!visible);
    setShowPaymentModal(true);
    setShowBankModal(false);
    setSelectedPaymentOption("paystack");
  };

  const handleBankModal = () => {
    setBankBtn(true);
    setShowBankModal(true);
    setShowPaymentModal(false);
    setSelectedPaymentOption("bankTransfer");
    console.log("Selected Payment Option:", "bankTransfer");
  };

  useEffect(() => {
    if (selectedCountry === "NG") {
      setShippingFee(10);
    } else if (selectedCountry) {
      setShippingFee(80);
    } else {
      setShippingFee(0);
    }
  }, [selectedCountry]);

  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );
  const total = subtotal + shippingFee;

  const sendOrderDetails = async () => {
    const orderDetails = {
      ...userDetails,
      items: cart.map((item) => `${item.product.name} x ${item.quantity}`),
      totalAmount: total,
      deliveryFee: shippingFee,
    };

    try {
      const response = await fetch("https://formspree.io/f/xeqbbejn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderDetails),
      });

      if (response.ok) {
        console.log("Order details sent successfully");
      } else {
        console.error("Error sending order details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const usdToNgn = (usdAmount) => {
    const exchangeRate = 1500; // 1500 NGN per 1 USD
    return Math.round(usdAmount * exchangeRate);
  };

  const paystackOptions = {
    email: userDetails.email,
    amount: usdToNgn(total) * 100, // amount in kobo (smallest unit of NGN)
    currency: "NGN",
    publicKey: "pk_live_31623679265ad901114d505a0e83109332561007",
    text: "Pay Now via Paystack",
    onSuccess: (response) => {
      sendOrderDetails();
      setCart([]);
      setPaymentSuccessful(true);
      console.log("Payment Successful!", response);
    },
    onClose: () => {
      console.log("Payment Closed!");
    },
  };
  const toggleSummary = () => {
    setShowSummary((prevShowSummary) => !prevShowSummary);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPaymentOption === "bankTransfer") {
      setShowConfirmPaymentModal(true);
    } else if (selectedPaymentOption === "paystack") {
      // Trigger Paystack payment
    }
  };

  const getCountryFlag = (countryCode) => {
    return countryCode
      ? `https://flagcdn.com/16x12/${countryCode.toLowerCase()}.png`
      : "";
  };

  return (
    <div className="checkout-container">
      {paymentSuccessful && cart.length === 0 ? (
        <div className="success-message">
          <iframe src="https://lottie.host/embed/4056d5d7-2cc3-4b68-9ea7-9d1969139431/CjMowrygcC.json"></iframe>
          <button className="continue-shopping-button">Shop Again</button>
        </div>
      ) : (
        <>
          <div className="checkout-summary">
            <div className="summary-toggle" onClick={toggleSummary}>
              <p>
                {showSummary ? "Hide" : "Show"} order summary{" "}
                <span>
                  <MdOutlineArrowDropDown />
                </span>
              </p>
              <p>${new Intl.NumberFormat("en-NG").format(total)}</p>
            </div>
            {showSummary && (
              <div className="summary-content">
                {cart.map((item, index) => (
                  <div key={index} className="sumcon">
                    <div className="sumcon-image">
                      <img
                        src={item.product.image}
                        alt="images"
                        className="checkoutImage"
                      />
                      <p className="checkoutimageNumber">{item.quantity}</p>
                    </div>
                    <div className="summaryOerder">
                      <p className="summaryOerderF summaryOerderName">
                        {item.product.name} <br />
                        <span style={{ fontSize: 13, margin: 0 }}>
                          size: {selectedSize[item.product.id] || "N/A"}
                        </span>
                      </p>

                      <p className="summaryOerderF">
                        $
                        {new Intl.NumberFormat("en-NG").format(
                          item.product.price * item.quantity
                        )}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="buttomCartCheckout">
                  <span className="cartTotal">
                    <p className="subtotal">Subtotal:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>
                  <span className="shipping">
                    <p className="shiptext">Shipping:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(shippingFee)}
                    </p>
                  </span>
                  <span className="shipping">
                    <p className="shiptext">Total:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(total)}
                    </p>
                  </span>
                </div>
              </div>
            )}

            <div className="paymentDelivery">
              <h3>Delivery</h3>
              <form onSubmit={handleSubmit} className="formWrapper">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      setSelectedState("");
                      setSelectedCity("");
                    }}
                    required
                  >
                    <option value="">Country/Region</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setSelectedCity("");
                    }}
                    disabled={!selectedCountry}
                    required
                  >
                    <option value="">State</option>
                    {State.getStatesOfCountry(selectedCountry).map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <img
                        src={getCountryFlag(selectedCountry)}
                        alt={selectedCountry}
                        className="country-flag"
                      />
                    </div>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        {selectedCountry
                          ? Country.getCountryByCode(selectedCountry).phonecode
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>

                <h3>Payment</h3>
                {selectedCountry && (
                  <p className="within">
                    {selectedCountry === "NG"
                      ? "Shipping within Nigeria: $10"
                      : "International shipping: $80"}
                  </p>
                )}
                <p className="tran">
                  All transactions are secure and encrypted
                </p>
                <div
                  className={`payra ${
                    selectedPaymentOption === "paystack" ? "active" : "incative"
                  }`}
                  onClick={handleShowModal}
                >
                  <div
                    style={{ display: "flex", gap: 10, alignItems: "center" }}
                  >
                    <input
                      type="radio"
                      name="pay"
                      id="paystack"
                      checked={selectedPaymentOption === "paystack"}
                      readOnly
                    />
                    <label htmlFor="pasy">Paystack</label>
                  </div>
                  <img src={Payment} alt="Paystack" className="payemntImg" />
                </div>
                {showPaymentModal && (
                  <div className="PayStackMo">
                    <img src={PaymentImg} alt="opayemntImg" />
                    <p>
                      After clicking "Pay now", you will be redirected to
                      Paystack to complete your purchase securely
                    </p>
                  </div>
                )}

                <div
                  className={`payra ${
                    selectedPaymentOption === "bankTransfer"
                      ? "active"
                      : "incative"
                  }`}
                  onClick={handleBankModal}
                >
                  <div
                    style={{ display: "flex", gap: 10, alignItems: "center" }}
                  >
                    <input
                      type="radio"
                      name="pay"
                      id="bankTransfer"
                      checked={selectedPaymentOption === "bankTransfer"}
                      readOnly
                    />
                    <label htmlFor="pay">Bank Transfer</label>
                  </div>
                </div>
                {showBankModal && (
                  <div className="bankMo">
                    <p>
                      Kindly note that our conversion rate is 1,500 NGN to $1,
                      and we will confirm all payments before processing your
                      order. Make a bank transfer in Nigerian Naira (NGN) of the
                      required amount to the following account: Name: DON-T BRAG
                      LUXURY•••Account Number: 8137816749 Bank Name: MONIEPOINT
                      MFB••• Please contact 08137816749 with your detailed proof
                      of payment confirmation. Please note that we confirm all
                      payments before processing orders. Thank you for your
                      cooperation
                    </p>
                  </div>
                )}

                <div className="summary-content">
                  {cart.map((item, index) => (
                    <div key={index} className="sumcon">
                      <div className="sumcon-image">
                        <img
                          src={item.product.image}
                          alt="images"
                          className="checkoutImage"
                        />
                        <p className="checkoutimageNumber">{item.quantity}</p>
                      </div>
                      <div className="summaryOerder">
                        <p className="summaryOerderF summaryOerderName">
                          {item.product.name} <br />
                          <span style={{ fontSize: 13, margin: 0 }}>
                            size: {selectedSize[item.product.id] || "N/A"}
                          </span>
                        </p>
                        <p className="summaryOerderF">
                          $
                          {new Intl.NumberFormat("en-NG").format(
                            item.product.price * item.quantity
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="buttomCartCheckout">
                    <span className="cartTotal">
                      <p className="subtotal">Subtotal:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(subtotal)}
                      </p>
                    </span>
                    <span className="shipping">
                      <p className="shiptext">Shipping:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(shippingFee)}
                      </p>
                    </span>
                    <span className="shipping">
                      <p className="shiptext">Total:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(total)}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="bottomButton">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="saveInfo"
                      />
                      <label className="form-check-label" htmlFor="saveInfo">
                        Save this information for next time
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    {selectedPaymentOption === "paystack" ? (
                      <PaystackButton
                        {...paystackOptions}
                        className="btnPaynows"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btnPaynows"
                        onClick={handleSubmit}
                      >
                        Pay now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {showConfirmPaymentModal && (
            <ConfirmPaymentModal
              onClose={() => setShowConfirmPaymentModal(false)}
              onPaymentMade={() => {
                setShowConfirmPaymentModal(false);
                // const whatsappNumber = "+2347072855007";
                const message = encodeURIComponent(
                  "Hello, I've made a payment for my order. Here's my receipt: "
                );
                const whatsappUrl = `https://wa.me/${+2348137816749}?text=${message}`;
                window.open(whatsappUrl, "_blank");
              }}
            />
          )}
          {isOpen && <PaymentApproved setIsOpen={setIsOpen} />}
        </>
      )}
    </div>
  );
};

export default Checkout;
