import React from "react";
// import Navbar from "../components/Navbar";
// import pr1 from "../assets/image/pr1.png";
// import pr2 from "../assets/image/pr2.png";
// import pr3 from "../assets/image/pr3.png";
// import pr4 from "../assets/image/pr4.png";
import "../styles/shop.css";
import Footer from "../components/Footer";
import ProductPage from "../components/Shops/Product";

const Shop = () => {
  return (
    <div className="general">
      

      <div className="shop">
        {/* <div className="filter">
          <h2>Filter </h2>
          <div className="color"></div>
        </div> */}
        <div className="shopCont">
          <ProductPage />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Shop;
