import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  message,
  Select,
} from "antd";
import "../styles/adminDash.css";
import axiosInstance from "../redux/api";

const cloudinaryCloudName = "dhqnrwxzv"; // Replace with your cloud name

const AdminDashboard = () => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState("");
  const [categories, setCategories] = useState([]);

  const handleSingleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const localURL = URL.createObjectURL(file);
      setSelectedImage(file);
      setImageURL(localURL);
    }
  };

  const handleMultipleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const localURLs = files.map((file) => URL.createObjectURL(file));
      setSelectedImages(files);
      setImageURLs(localURLs);
    } else {
      setSelectedImages([]);
      setImageURLs([]);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/categories/");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "qpa8opzy"); // Replace with your upload preset

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message);
      }

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      throw error;
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    try {
      if (selectedImage) {
        const uploadedURL = await uploadToCloudinary(selectedImage);
        values.image = uploadedURL;
      }

      if (selectedImages.length) {
        const uploadedImageURLs = await Promise.all(
          selectedImages.map(uploadToCloudinary)
        );
        values.images = uploadedImageURLs;
      }

      values.sizes = values.sizes || [];
      console.log("Final form submission payload:", values);

      const response = await axiosInstance.post("/api/v1/products/", values);

      if (response.status === 200 || response.status === 201) {
        message.success("Product added successfully!");
        form.resetFields();
        setSelectedImage(null);
        setSelectedImages([]);
        setImageURL("");
        setImageURLs([]);
      } else {
        message.error("There was an error adding the product.");
      }
    } catch (error) {
      message.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-dashboard-container">
      <h2 className="admin-dashboard-title">Add New Product</h2>

      <Form
        layout="vertical"
        onFinish={handleFormSubmit}
        form={form}
        className="admin-dashboard-form"
      >
        <Form.Item
          label="Product Image"
          name="image"
          rules={[
            {
              required: imageURL === "",
              message: "Product Image is required!",
            },
          ]}
        >
          <Input type="file" onChange={handleSingleImageChange} />
          {imageURL && (
            <img
              src={imageURL}
              alt="Product"
              style={{ width: "100px", marginTop: "10px" }}
            />
          )}
        </Form.Item>

        <Form.Item
          label="Product Images"
          name="images"
          rules={[
            {
              required: selectedImages.length === 0,
              message: "At least one product image is required!",
            },
          ]}
        >
          <Input type="file" multiple onChange={handleMultipleImageChange} />
          <div style={{ display: "flex", marginTop: "10px" }}>
            {imageURLs.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Product ${index}`}
                style={{ width: "100px", marginRight: "10px" }}
              />
            ))}
          </div>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Description is required!" }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: "Category is required!" }]}
        >
          <Select placeholder="Select a category">
            {categories.map((category) => (
              <Select.Option key={category._id} value={category.name}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Sizes Available"
          name="sizes"
          rules={[{ required: true, message: "Sizes are required!" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select available sizes"
            style={{ width: "100%" }}
          >
            <Option value="S">S</Option>
            <Option value="M">M</Option>
            <Option value="L">L</Option>
            <Option value="XL">XL</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Price is required!" }]}
        >
          <InputNumber placeholder="Enter price" min={0} />
        </Form.Item>

        <Form.Item
          label="Rating"
          name="rating"
          rules={[
            {
              required: true,
              message: "Rating is required!",
              type: "number",
              min: 0,
              max: 5,
            },
          ]}
        >
          <InputNumber placeholder="Enter rating" min={0} max={5} />
        </Form.Item>

        <Form.Item
          label="Number of Reviews"
          name="numReviews"
          rules={[
            { required: true, message: "Number of Reviews is required!" },
          ]}
        >
          <InputNumber placeholder="Enter number of reviews" min={0} />
        </Form.Item>

        <Form.Item label="Featured" name="isFeatured" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="OutofStock"
          name="isOutofStock"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Stock Count"
          name="stockInCount"
          rules={[{ required: true, message: "Stock Count is required!" }]}
        >
          <InputNumber placeholder="Enter stock count" min={0} />
        </Form.Item>

        <Form.Item className="submit-button-container">
          <Button type="primary" htmlType="submit" loading={loading}>
            Add Product
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminDashboard;
