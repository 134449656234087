/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../redux/api";

const CategoryPage = () => {
  const { slug } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    fetchCategoryProducts();
  }, [slug]);

  const fetchCategoryProducts = async () => {
    console.log("Fetching products for category:", slug);
    try {
      const response = await axiosInstance.get(
        `/api/v1/products?category=${slug}`
      );
      console.log("Received data:", response.data);
      setCategoryProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching category products:", error);
    }
  };

  return (
    <div className="coll">
      {categoryProducts.length > 0 ? (
        categoryProducts.map((product) => (
          <div className="colPro" key={product._id}>
            <img
              src={product.image}
              alt={product.name}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
            />
            <h2 className="h2Name">{product.name}</h2>
            <div className="proPri">
              <p>${new Intl.NumberFormat("en-NG").format(product.price)}</p>
            </div>
          </div>
        ))
      ) : (
        <p>No products found for this category.</p>
      )}
    </div>
  );
};

export default CategoryPage;
