// cartStyles.js
import styled from "styled-components";
import { Button } from "antd";
import { Link } from "react-router-dom";

export const CartContainer = styled.div`
  max-width: 1200px;
  /* margin: 50px auto; */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const CartTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
`;

export const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

export const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-right: 10px;
  border-radius: 10px;
`;

export const CartItemDetails = styled.div`
  flex: 1;
`;

export const CartItemName = styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  margin: 0;
`;

export const CartItemPrice = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
  margin: 0;
`;

export const CartItemQuantity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    margin: 0 10px;
    font-size: 1.1rem;
    color: #333;
  }
`;

export const CartItemActions = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  padding: 0.2rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  /* position: relative;
  bottom: 5.5em;
  left: 15em; */
  border: none;
  color: #ff4d4f;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #ff1f1f;
  }
`;

export const CheckoutButton = styled(Button)`
  background-color: #500157;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  align-items: center;
  display: flex;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 3em;

  &:hover {
    background-color: #0056b3;
  }
`;


export const TotalSection = styled.div`
  text-align: right;
  margin-bottom: 20px;
`;

export const CartTotal = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
`;

export const CartDeliveryFee = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 10px;
`;

export const CartGrandTotal = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
`;

export const CartSummary = styled.div`
  /* margin-top: 30px; */
`;

export const EmptyCart = styled.div`
  text-align: center;
  /* margin-top: 50px; */

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
`;

export const EditDetailsButton = styled(Button)`
  margin-right: 10px;
  background: #f0ad4e;
  border-color: #eea236;
  color: #fff;
  transition: background-color 0.3s;

  &:hover {
    background: #ec971f;
    border-color: #d58512;
  }
`;

export const ShopAgainButton = styled(Link)`
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const SuccessMessage = styled.div`
  text-align: center;
  margin-top: 50px;

  h2 {
    font-size: 2rem;
    color: #52c41a;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
  }
`;

export const DeliveryDetails = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 10px;
  }
`;

export const ContinueShoppingButton = styled(Link)`
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
