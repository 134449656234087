// ProductItem.js
import React from "react";
import { ProductImage, ProductImageWrapper, ProductItemBottom } from "./styles";

import ContentLoader from "react-content-loader";

const ProductItem = ({ product, setSelectedProduct, setShowModal }) => {
  const handleCardClick = () => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  return (
    <div onClick={handleCardClick}>
      <ProductImageWrapper>
        {product.image ? (
          <ProductImage
            src={product.image}
            alt={product.name}
            style={{ width: "185px", height: "200px", objectFit: "contain" }}
          />
        ) : (
          <ContentLoader
            speed={2}
            width="100%"
            height={150}
            viewBox="0 0 100% 150"
            backgroundColor="#F2F2F2"
            foregroundColor="#F2F2F2"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="150" />
          </ContentLoader>
        )}
      </ProductImageWrapper>

      {/* <productNameShop>{product.name}</productNameShop> */}
      <ProductItemBottom>
        <p>{product.name}</p>
        <p>${new Intl.NumberFormat("en-NG").format(product.price)}</p>
      </ProductItemBottom>
    </div>
  );
};

export default ProductItem;
