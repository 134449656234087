/* eslint-disable jsx-a11y/iframe-has-title */
// Cart.js
import React from "react";
import { useCart } from "../components/Cart/cartContext";
import {
  CartContainer,
  CartItem,
  ProductImage,
  CartItemDetails,
  CartItemName,
  CartItemPrice,
  CartItemQuantity,
  CheckoutButton,
  EmptyCart,
  QuantityButton,
  RemoveButton,
} from "../styles/cartStyles";
import { Link } from "react-router-dom";

const Cart = () => {
  const { cart, setCart, selectedSize } = useCart();
  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );

  const incrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(newCart);
  };

  const decrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(newCart);
  };

  const removeFromCart = (productId) => {
    const newCart = cart.filter((item) => item.product.id !== productId);
    setCart(newCart);
  };

  return (
    <CartContainer>
      {cart.length === 0 ? (
        <EmptyCart>
          <h4>Your Cart is Empty</h4>
          <div className="emptyCart">
            <iframe src="https://lottie.host/embed/aa738e8a-57bd-4d55-a452-99bc37cdd0e5/rFpQfBxcRM.json"></iframe>

            <Link to="/shop">
              <button className="continueShoppingButton">Go to Shop</button>
            </Link>
          </div>
        </EmptyCart>
      ) : (
        <>
          {cart.map((item, index) => (
            <CartItem key={index}>
              <ProductImage src={item.product.image} alt={item.product.name} />

              <CartItemDetails>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CartItemName>{item.product.name}</CartItemName>

                  <RemoveButton onClick={() => removeFromCart(item.product.id)}>
                    X
                  </RemoveButton>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CartItemPrice>
                    ${new Intl.NumberFormat("en-NG").format(item.product.price)}
                  </CartItemPrice>

                  <CartItemQuantity>
                    <QuantityButton
                      onClick={() => decrementQuantity(item.product.id)}
                    >
                      -
                    </QuantityButton>
                    <span style={{ fontSize: "14px" }}>{item.quantity}</span>
                    <QuantityButton
                      onClick={() => incrementQuantity(item.product.id)}
                    >
                      +
                    </QuantityButton>
                  </CartItemQuantity>
                  <div>
                    <p style={{ fontSize: 13, margin: 0 }}>
                      size: {selectedSize[item.product.id] || "N/A"}
                    </p>
                  </div>
                </div>
              </CartItemDetails>
            </CartItem>
          ))}
          <div className="buttomCart">
            <span className="cartTotal">
              <p className="subtotal">Subtotal:</p>
              <p className="total">
                {" "}
                ${new Intl.NumberFormat("en-NG").format(subtotal)}
              </p>
            </span>

            <span className="shipping">
              <p className="shiptext">Shipping: </p>
              <p className="total">Calculated at checkout</p>
            </span>

            <span className="shipping">
              <p className="shiptext">Total: </p>
              <p className="total">
                {" "}
                ${new Intl.NumberFormat("en-NG").format(subtotal)}
              </p>
            </span>
          </div>
          <Link to="/checkout">
            <CheckoutButton>Check out</CheckoutButton>
          </Link>
        </>
      )}
    </CartContainer>
  );
};

export default Cart;
