import React, { useState, useEffect } from "react";
import styles from "../styles/getAllItem.module.css";
import DeleteAndEditModal from "../components/Shops/DeleteAndEditProducts";
import axiosInstance from "../redux/api";

const GetAllItem = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleProductClick = (product) => {
    console.log("Opening modal for product:", product);
    setSelectedProduct(product);
    setShowModal(true);
  };

  useEffect(() => {
    // Fetch products on component mount
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get("/api/v1/products/all");
        if (response.data.products) {
          setProducts(response.data.products);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div className={styles.container}>Loading products...</div>;
  }

  const deleteProduct = async (productId) => {
    try {
      const response = await axiosInstance.delete(
        `/api/v1/products/${productId}`
      );
      if (response.status === 200) {
        console.log(`Product with ID ${productId} deleted successfully.`);
        // Update the products state to reflect the deletion
        setProducts((currentProducts) =>
          currentProducts.filter((product) => product._id !== productId)
        );
      }
    } catch (error) {
      console.error(
        `Error during deletion of product with ID ${productId}:`,
        error
      );
      // The error handling is now managed by the axiosInstance interceptors
    }
  };

  const updateProduct = (updatedProduct) => {
    console.log("Updating product in list:", updatedProduct); // Log the updated product
    setProducts((currentProducts) =>
      currentProducts.map((product) =>
        product._id === updatedProduct._id ? updatedProduct : product
      )
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>All Products</h2>
      {products.length > 0 ? (
        <ul className={styles.productList}>
          {products.map((product) => (
            <li
              key={product._id}
              className={styles.productItem}
              onClick={() => handleProductClick(product)}
            >
              <img
                src={product.image}
                alt={product.name}
                className={styles.productImage}
              />
              <div className={styles.productDetails}>
                <h3 className={styles.productName}>
                  Product Name: {product.name}
                </h3>
                <p>Rich Description: {product.richDescription}</p>
                <p>Brand: {product.brand}</p>
                <p>
                  Price: ${" "}
                  {new Intl.NumberFormat("en-NG").format(product.price)}
                </p>
                <p>Rating: {product.rating}</p>
                <p>Reviews: {product.numReviews}</p>
                {product.isFeatured && (
                  <p className={styles.featured}>Featured</p>
                )}
                <p>
                  Date Created:{" "}
                  {new Date(product.dateCreated).toLocaleDateString()}
                </p>
                <div className={styles.multipleImagesContainer}>
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name} ${index + 1}`}
                      className={styles.multipleProductImage}
                    />
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.noProducts}>No products found!</p>
      )}
      <DeleteAndEditModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        product={selectedProduct}
        handleDelete={deleteProduct}
        handleEdit={updateProduct}
      />
    </div>
  );
};

export default GetAllItem;
