import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeaturedProducts } from "../redux/slice/featuredProductsSlice";
import "../styles/home.css";
import homee from "../assets/image/won.png";
import im1 from "../assets/image/1.jpg";
import im2 from "../assets/image/30.jpg";
import im3 from "../assets/image/45.jpg";
import im4 from "../assets/image/31.jpg";
import im5 from "../assets/image/8.jpg";
import im6 from "../assets/image/im6.jpg";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Footer from "../components/Footer";
import ProductModal from "../components/Shops/ProductModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const dispatch = useDispatch();
  const { products: featuredProducts, status } = useSelector(
    (state) => state.featuredProducts
  );
  const isLoading = status === "loading";

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBestSellersIndex, setCurrentBestSellersIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchFeaturedProducts());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (featuredProducts.length > 0) {
      setCurrentBestSellersIndex(featuredProducts.length - (isMobile ? 10 : 4));
    }
  }, [featuredProducts, isMobile]);

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= featuredProducts.length ? 0 : nextIndex;
    });
  };

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? featuredProducts.length - 1 : nextIndex;
    });
  };

  const handleBestSellersLeftClick = () => {
    setCurrentBestSellersIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? featuredProducts.length - 1 : nextIndex;
    });
  };

  const handleBestSellersRightClick = () => {
    setCurrentBestSellersIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= featuredProducts.length ? 0 : nextIndex;
    });
  };

  const handleNewArrivalsSwipe = (direction) => {
    if (direction === "Left") {
      handleRightClick();
    } else if (direction === "Right") {
      handleLeftClick();
    }
  };

  const handleBestSellersSwipe = (direction) => {
    if (direction === "Left") {
      handleBestSellersRightClick();
    } else if (direction === "Right") {
      handleBestSellersLeftClick();
    }
  };

  const newArrivalsSwipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNewArrivalsSwipe("Left"),
    onSwipedRight: () => handleNewArrivalsSwipe("Right"),
  });

  const bestSellersSwipeHandlers = useSwipeable({
    onSwipedLeft: () => handleBestSellersSwipe("Left"),
    onSwipedRight: () => handleBestSellersSwipe("Right"),
  });

  const imageStyles = [
    {
      rotateX: "21deg",
      rotateY: "33deg",
      top: "44px",
      height: "433px",
      left: "55px",
    },
    {
      rotateX: "8deg",
      rotateY: "42deg",
      top: "44px",
      height: "500px",
      left: "-50px",
    },
    {
      rotateX: "15deg",
      rotateY: "0deg",
      top: "0px",
      height: "600px",
      left: "-93px",
    },
    {
      rotateX: "-32deg",
      rotateY: "16deg",
      top: "20px",
      height: "508px",
      left: "-100px",
    },
    {
      rotateX: "-32deg",
      rotateY: "16deg",
      top: "55px",
      height: "379px",
      left: "-112px",
    },
    {
      rotateX: "-32deg",
      rotateY: "16deg",
      top: "55px",
      height: "379px",
      left: "-112px",
    },
  ];

  const imageSources = [im1, im2, im3, im4, im5, im6];

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  return (
    <div className="general">
      {isMobile && (
        <div className="homeMobile">
          <div className="shopWant">
            <p>Trendy Collection</p>
            <Link to="/shop">
              <button className="shopfor">Shop for what you want!</button>
            </Link>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="home">
          <div className="homeContent">
            <div className="text">
              <h1>Shop for what you want!</h1>
              <h2>Trendy Collection</h2>
              <h3>
                Ready to refresh your wardrobe? Start shopping with us today and
                discover a world of fashion at your fingertips!
              </h3>
            </div>
            <div className="hoImg">
              <img src={homee} alt="" />
            </div>
          </div>
        </div>
      )}
      <div className="product">
        {/* <h2>Our Top Selling Product</h2>
        <p className="ab">
          Don't miss out on the opportunity to own the fashion piece that's
          taking the world by storm. Elevate your style, embrace comfort, and
          make a statement with our service
        </p> */}

        {/* <div className="proImg">
          {isMobile ? (
            <Slider {...settings}>
              {imageSources.map((src, index) => (
                <div key={index}>
                  <img
                    src={src}
                    alt={`Fashion item ${index + 1}`}
                    style={{ height: imageStyles[index].height }}
                    className="mobile-image"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="proCon">
              {imageSources.map((src, index) => (
                <div
                  key={index}
                  className="rotating-container"
                  style={{
                    transform: `rotateX(${imageStyles[index].rotateX}) rotateY(${imageStyles[index].rotateY})`,
                    top: imageStyles[index].top,
                    height: imageStyles[index].height,
                    left: imageStyles[index].left,
                  }}
                >
                  <img
                    src={src}
                    alt={`Fashion item ${index + 1}`}
                    style={{ height: imageStyles[index].height }}
                  />
                </div>
              ))}
            </div>
          )}
        </div> */}

        <div className="proCollection">
          {/* <div className="proTog">
            <div className="left" onClick={handleLeftClick}>
              <MdKeyboardArrowLeft className="proIcon" />
            </div>
            <div className="right" onClick={handleRightClick}>
              <MdKeyboardArrowRight className="proIcon" />
            </div>
          </div> */}
          <p className="newArr">New Arrivals</p>
          <div className="coll" {...newArrivalsSwipeHandlers}>
            {isLoading
              ? Array.from({ length: isMobile ? 2 : 4 }).map((_, index) => (
                  <div className="colPro" key={index}>
                    <Skeleton width="100%" height={200} />
                    <h2 className="h2Name">
                      <Skeleton width={200} />
                    </h2>
                    <div className="proPri">
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                ))
              : featuredProducts
                  .slice(currentIndex, currentIndex + (isMobile ? 6 : 8))
                  .map((product) => (
                    <div
                      className="colPro"
                      key={product._id}
                      onClick={() => handleProductClick(product)}
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "contain",
                        }}
                      />

                      <div className="proPri">
                        <h2 className="h2Name">{product.name}</h2>
                        <p>
                          $
                          {new Intl.NumberFormat("en-NG").format(product.price)}
                        </p>
                      </div>
                    </div>
                  ))}
          </div>
          <p className="newArr">Best Sellers</p>
          {/* <div className="proTog">
            <div className="leftB" onClick={handleBestSellersLeftClick}>
              <MdKeyboardArrowLeft className="proIcon" />
            </div>
            <div className="rightB" onClick={handleBestSellersRightClick}>
              <MdKeyboardArrowRight className="proIcon" />
            </div>
          </div> */}
          <div className="coll" {...bestSellersSwipeHandlers}>
            {isLoading
              ? Array.from({ length: isMobile ? 2 : 4 }).map((_, index) => (
                  <div className="colPro" key={index}>
                    <Skeleton width="100%" height={200} />
                    <h2 className="h2Name">
                      <Skeleton width={200} />
                    </h2>
                    <div className="proPri">
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                ))
              : featuredProducts
                  .slice(
                    currentBestSellersIndex,
                    currentBestSellersIndex + (isMobile ? 10 : 8)
                  )
                  .map((product) => (
                    <div
                      className="colPro"
                      key={product._id}
                      onClick={() => handleProductClick(product)}
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "contain",
                        }}
                      />

                      <div className="proPri">
                        <h2 className="h2Name">{product.name}</h2>
                        <p>
                          $
                          {new Intl.NumberFormat("en-NG").format(product.price)}
                        </p>
                      </div>
                    </div>
                  ))}
          </div>
        </div>
        {isModalOpen && selectedProduct && (
          <ProductModal
            showModal={isModalOpen}
            setShowModal={setIsModalOpen}
            product={selectedProduct}
          />
        )}
      </div>

      {isMobile && (
        <div className="natureReady">
          <h4> Ready to refresh your wardrobe?</h4>
          <p>
            Elevate your style, embrace comfort, and make a statement with our
            service
          </p>
          <div className="natureRed">
            <Link to="/shop">
              <button className="shopfor">Shop Now!</button>
            </Link>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Home;
