import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useCart } from "../Cart/cartContext";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaShoppingBasket } from "react-icons/fa";
import styles from "../../styles/productModal.module.css";

const ProductModal = ({ showModal, setShowModal, product }) => {
  const { cart, setCart, selectedSize, handleSizeSelect, addToCart } =
    useCart();
  const navigate = useNavigate();
  const [hasAdded, setHasAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [currentProduct, setCurrentProduct] = useState(product);
  const expectedSizes = ["XS", "S", "M", "L", "XL", "XXL"];

  const { products: relatedProducts, status } = useSelector(
    (state) => state.featuredProducts
  );
  const [shuffledRelatedProducts, setShuffledRelatedProducts] = useState([]);

  const modalBodyRef = useRef(null);

  console.log("Redux state:", { relatedProducts, status });

  useEffect(() => {
    console.log("relatedProducts changed:", relatedProducts);
    if (relatedProducts.length > 0) {
      const shuffled = shuffleArray(relatedProducts);
      console.log("Shuffled products:", shuffled);
      setShuffledRelatedProducts(shuffled);
    } else {
      console.log("No related products to shuffle");
      setShuffledRelatedProducts([]);
    }
  }, [relatedProducts]);

  const scrollToTop = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const shuffleArray = (array) => {
    console.log("Shuffling array:", array);
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const handleProductChange = (newProduct) => {
    console.log("Changing product to:", newProduct);
    setCurrentProduct(newProduct);
    setHasAdded(false);
    setQuantity(1);
    scrollToTop();
  };

  const handleAddToCart = () => {
    addToCart(currentProduct, quantity, selectedSize[currentProduct.id]);
    setHasAdded(true);
  };

  const removeFromCart = () => {
    setCart((currentCart) =>
      currentCart.filter((item) => item.product.id !== currentProduct.id)
    );
    setHasAdded(false);
  };

  const handleGoToCart = () => {
    navigate("/cart");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHasAdded(false);
  };

  const incrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(newCart);
    if (currentProduct.id === productId) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const decrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(newCart);
    if (currentProduct.id === productId) {
      setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    }
  };

  console.log("Rendering ProductModal with state:", {
    currentProduct,
    relatedProducts: shuffledRelatedProducts,
    status,
  });

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
      <Modal.Body ref={modalBodyRef}>
        <Row>
          <Col md={6}>
            <img
              src={currentProduct.image}
              alt={currentProduct.name}
              className={styles.productImage}
            />
          </Col>
          <Col md={6}>
            <p className={styles.productName}>{currentProduct.name}</p>
            <p className={styles.price}>
              <span>Price: </span>$
              {new Intl.NumberFormat("en-NG", {
                style: "decimal",
                maximumFractionDigits: 2,
              }).format(currentProduct.price)}
            </p>
            <div className={styles.sizeSelector}>
              <p className={styles.avai}>Available Sizes</p>
              <div className={styles.sizeOptions}>
                {expectedSizes.map((size) => (
                  <span
                    key={size}
                    className={`${styles.sizeOption} ${
                      currentProduct.sizes.includes(size)
                        ? styles.boxes
                        : styles.unavailableSize
                    } ${
                      selectedSize[currentProduct.id] === size
                        ? styles.selectedSize
                        : ""
                    }`}
                    onClick={() =>
                      currentProduct.sizes.includes(size) &&
                      handleSizeSelect(size, currentProduct.id)
                    }
                  >
                    {size}
                  </span>
                ))}
              </div>
            </div>
            <div className={styles.quantityButtons}>
              <div
                className={styles.quantityButton}
                onClick={() => decrementQuantity(currentProduct.id)}
              >
                -
              </div>
              <span className={styles.quantityValue}>{quantity}</span>
              <div
                className={styles.quantityButton}
                onClick={() => incrementQuantity(currentProduct.id)}
              >
                +
              </div>
            </div>

            <div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                {cart.some((item) => item.product.id === currentProduct.id) ? (
                  <Button variant="danger" onClick={removeFromCart}>
                    Remove <FaShoppingBasket />
                  </Button>
                ) : hasAdded ? (
                  <Button variant="success" disabled>
                    Added to Cart
                  </Button>
                ) : (
                  <Button
                    onClick={handleAddToCart}
                    className={styles.addToCartButton}
                  >
                    Add to Cart
                  </Button>
                )}
                {hasAdded && (
                  <Button onClick={handleGoToCart} variant="success">
                    Go to Cart
                  </Button>
                )}
              </Modal.Footer>
            </div>

            <div className={styles.related}>
              <p className={styles.have}>Have you seen these ?</p>
              {status === "loading" ? (
                <p>Loading related products...</p>
              ) : shuffledRelatedProducts.length > 0 ? (
                <div className={styles.relatedProducts}>
                  {shuffledRelatedProducts.map((product) => (
                    <div
                      key={product._id}
                      className={styles.relatedProduct}
                      onClick={() => handleProductChange(product)}
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className={styles.relatedImg}
                      />
                      <p className={styles.relatedName}>{product.name}</p>
                      <p className={styles.relatedPrice}>
                        ${new Intl.NumberFormat("en-NG").format(product.price)}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No related products found.</p>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
