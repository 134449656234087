import React from "react";
import styles from "./recpeipt.module.css";
import ApprovedImg from "../../assets/image/Approve(Tick).png";
import { Link } from "react-router-dom";
function Receipt({ setIsOpen }) {
  return (
    <div className={styles.darkBG} onClick={() => setIsOpen(false)}>
      <div className={styles.centered}>
        <div className={styles.modal}>
          <img
            src={ApprovedImg}
            className={styles.ApprovedImg}
            alt="approvedImg"
          />
          <span className={styles.booking}>Payment Successful</span>

          <div className={styles.skipDiv}>
            <Link
              className={styles.link}
              to="https://wa.me/message/JKPPC3QTBX3JE1"
              style={{ textDecoration: "none" }}
            >
              Share Receipt
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
