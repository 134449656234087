import React from 'react'
import styles from "./itemcard.module.css"
import rippedJean from "../../assets/image/pr1.png"

function ItemCard() {
  return (
    <div className={styles.general}>
      <div className={styles.body}>
        <img className={styles.ripped} src={rippedJean} alt='dance'/>
        <span className={styles.jean}>Crazy Jean</span>
        <div className={styles.priceDiv}>
            <span className={styles.price}>#9000</span>
            <span className={styles.buy}>Buy Now</span>
        </div>
      </div>
    </div>
  )
}

export default ItemCard
