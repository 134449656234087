/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Admin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = () => {
        // Mock credentials for demonstration purposes
        if (username === 'khalid' && password === 'Olubuade@12') {
            setErrorMsg('');
            setIsLoggedIn(true);
            // You can set a token or some state here to indicate successful login
            console.log("Logged in successfully!");
        } else {
            setErrorMsg("Invalid username or password.");
            setIsLoggedIn(false);
        }
    };

    return (
        <div style={{ maxWidth: '300px', margin: '0 auto', padding: '100px 0' }}>
            <h2>Admin Login</h2>
            {errorMsg && <Alert message={errorMsg} type="error" style={{ marginBottom: '20px' }} />}
            <Form
                name="admin_login"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input 
                        prefix={<UserOutlined className="site-form-item-icon" />} 
                        placeholder="Username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <a className="login-form-forgot" href="#" style={{ float: 'right' }}>
                        Forgot password?
                    </a>
                </Form.Item>

                <Form.Item>
                    {isLoggedIn ? (
                        <div style={{
                            "display": "flex",
                            "justifyContent": "space-around",
                            "flexDirection": "column",
                            "gap": "30px"
                        }}>
                            <Link to="/admin-dashboard">
                            <Button type="primary" style={{ width: '100%' }}>
                             Upload New Products
                            </Button>
                        </Link>

                        <Link to="/all item">
                            <Button type="primary" style={{ width: '100%' }}>
                               See All Product In Store
                            </Button>
                        </Link>
                        </div>
                    ) : (
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Log in
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default Admin;