// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import featuredProductsReducer from "../slice/featuredProductsSlice";

export const store = configureStore({
  reducer: {
    featuredProducts: featuredProductsReducer,
    // ... other reducers
  },
});
