import React from "react";
import Footer from "../components/Footer";
import Ct from "../assets/image/gt1.png";
import { BsTelephonePlus } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import "../styles/contact.css";

const Contact = () => {
  return (
    <div className="general">
  
      <div className="getIn" style={{ backgroundImage: `url(${Ct})` }}>
        <h2>Get in Touch with us</h2>
        <p>
          Getting in touch with us is easy and we're here to assist you. Whether
          you have questions, feedback, or need support, our dedicated team is
          just a message or call away.
        </p>
      </div>

      <div className="conDetails">
        <div className="conOption">
          <div className="contactt">
            <h2>Need to quickly access to us via call</h2>
            <BsTelephonePlus className="conICon" />
            <p>+234xxxxxxxxxxx</p>
          </div>
          <div className="contactt">
            <h2>Need to quickly access to us via mail</h2>
            <MdOutlineEmail className="conICon" />
            <p>dontbrag@gmail.com</p>
          </div>
        </div>

        <h3>
          “We value your input and are committed to providing the best service
          possible. Feel free to reach out, and we'll be happy to help."
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
