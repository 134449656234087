import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import EditProductModal from "./EditPrroductModal";
import axiosInstance from "../../redux/api";

const DeleteAndEditModal = ({
  show,
  handleClose,
  product,
  handleDelete,
  handleEdit,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/api/v1/categories/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const openConfirmModal = () => setShowConfirmModal(true);
  const closeConfirmModal = () => setShowConfirmModal(false);

  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);

  const confirmDelete = async () => {
    try {
      await axiosInstance.delete(`/api/v1/products/${product._id}`);
      handleDelete(product._id);
      alert("Product successfully deleted!");
      closeConfirmModal();
      handleClose();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleEditConfirm = async (editedProduct) => {
    try {
      console.log("Received edited product:", editedProduct);
  
      // Do not convert category back to ID
      const productToUpdate = {
        ...editedProduct,
        category: editedProduct.category // Keep the category name
      };
  
      console.log("Sending updated product to backend:", productToUpdate);
  
      const response = await axiosInstance.put(
        `/api/v1/products/${productToUpdate._id}`,
        productToUpdate
      );
  
      if (response.status === 200) {
        console.log("Server response:", response.data);
        // Use the response data, but ensure we keep the category name
        const updatedProduct = {
          ...response.data,
          category: editedProduct.category // Ensure we keep the category name
        };
        handleEdit(updatedProduct);
        console.log("Product updated successfully");
        closeEditModal();
        handleClose();
      }
    } catch (error) {
      console.error("Error updating product:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
    }
  };

  if (!product) return null;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{product.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            alt=""
            src={product.image}
            style={{ width: "350px", height: "350px" }}
          />
          <p>
            <strong>Rich Description:</strong> {product.richDescription}
          </p>
          <p>
            <strong>Brand:</strong> {product.brand}
          </p>
          <p>
            <strong>Price:</strong> ${product.price}
          </p>
          <p>
            <strong>Rating:</strong> {product.rating}
          </p>
          <p>
            <strong>Reviews:</strong> {product.numReviews}
          </p>
          <p>
            <strong>Category:</strong>{" "}
            {product.category ? product.category.name : "N/A"}
          </p>
          {product.isFeatured && (
            <p>
              <strong>Featured Product</strong>
            </p>
          )}
          <p>
            <strong>Date Created:</strong>{" "}
            {new Date(product.dateCreated).toLocaleDateString()}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={openConfirmModal}>
            Delete
          </Button>
          <Button variant="primary" onClick={openEditModal}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmDeleteModal
        show={showConfirmModal}
        handleClose={closeConfirmModal}
        handleConfirmDelete={confirmDelete}
      />

      <EditProductModal
        show={showEditModal}
        handleClose={closeEditModal}
        product={product}
        handleEditConfirm={handleEditConfirm}
        categories={categories}
      />
    </>
  );
};

export default DeleteAndEditModal;
