import React from "react";
import "../styles/about.css";
import tr1 from "../assets/image/t1.png";
import tr2 from "../assets/image/t2.png";
import tr3 from "../assets/image/t3.png";
import Footer from "../components/Footer";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import {} from "react-icons/fa";

const About = () => {
  return (
    <div className="general">
      <div className="abHeader">About US</div>

      <div className="abSplit">
        <img src={tr1} alt="" />
        <div className="slText">
          {/* <h2>What is Don’t Brag</h2> */}
          <p>
            At DON'T BRAG, we believe that true style speaks for itself. Our
            name isn't just a label; it's a philosophy. We embrace the beauty of
            understatement and the power of authenticity. Instead of boasting,
            we let our designs do the talking. DON'T BRAG is a celebration of
            humility, elegance, and genuine self-expression. Join us on a
            journey where style is a statement, not a shout.
          </p>
        </div>
      </div>

      {/* <div className="abSplit abSplit2">
        <div className="slText">
          <h2>What is Don’t Brag</h2>
          <p>
            We provide a digital marketplace for fashion brands, designers,
            retailers, and individuals to showcase and sell a wide range of
            clothing, footwear, accessories, and sometimes even beauty products.
            From the latest trends to timeless classics, this online fashion
            destination has it all. With a user-friendly interface, secure
            shopping, and a commitment to customer satisfaction, it's your go-to
            source for effortless and fashionable shopping. Upgrade your
            wardrobe with just a click!
          </p>
        </div>
        <img src={tr2} alt="" />
      </div> */}
      {/* <div className="abSplit">
        <img src={tr3} alt="" />
        <div className="slText">
          <h2>What is Don’t Brag</h2>
          <p>
            We provide a digital marketplace for fashion brands, designers,
            retailers, and individuals to showcase and sell a wide range of
            clothing, footwear, accessories, and sometimes even beauty products.
            From the latest trends to timeless classics, this online fashion
            destination has it all. With a user-friendly interface, secure
            shopping, and a commitment to customer satisfaction, it's your go-to
            source for effortless and fashionable shopping. Upgrade your
            wardrobe with just a click!
          </p>
        </div>
      </div> */}

      <div className="qoute">
        <h2>Don’t Brag</h2>
        <p>
          <FaQuoteLeft /> At DON'T BRAG, we are more than just a fashion brand;
          we are a movement. Our name, DON'T BRAG, encapsulates our ethos of
          humility and authenticity. We believe that true style doesn't need to
          shout; it speaks volumes in its subtlety. By embracing understated
          elegance and genuine self-expression, we redefine the fashion
          narrative. DON'T BRAG is a testament to the power of quiet confidence
          and the beauty of letting our designs shine without the need for
          excessive self-promotion.
          <FaQuoteRight />
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default About;
