/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
// Navbar.js
import React, { useEffect, useState } from "react";
import logo from "../assets/image/log.png";
import "../styles/nav.css";
import { FaBars, FaTimes, FaCartPlus } from "react-icons/fa";
import { useCart } from "./Cart/cartContext";
import { Link } from "react-router-dom";
import axiosInstance from "../redux/api"; // Import the axios instance

const SideModal = ({ isOpen, onClose }) => {
  const { cart } = useCart();
  const [categories, setCategories] = useState([]);

  const fallbackCategories = [
    "Bags",
    "Tees",
    "Jean",
    "Caps/Hats",
    "Shirt",
    "Socks",
    "Underwear",
    "Shoes",
  ];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/categories/");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategories(fallbackCategories);
    }
  };

  return (
    <div className={`side-modal ${isOpen ? "open" : ""}`}>
      <div className="side-modal-content">
        <div className="side-modal-header">
          <img src={logo} alt="Logo" className="logoSide" />
          <button className="close-btn" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <Link to="/cart" className="cart-info" onClick={onClose}>
          <p>cart {cart.length}</p>
          <FaCartPlus />
        </Link>

        <div className="sideTextContainer">
          {categories.length > 0 ? (
            categories.map((category) => (
              <Link
                key={category._id}
                to={`/category/${
                  category.slug ||
                  (category.name
                    ? category.name.toLowerCase().replace(/\s+/g, "-")
                    : "")
                }`}
                onClick={onClose}
                className="sideText"
              >
                {category.name || ""}
              </Link>
            ))
          ) : (
            <p>No categories available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const CartModal = ({ isOpen, onClose }) => {
  const { cart, setCart, selectedSize } = useCart();
  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );

  const incrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(newCart);
  };

  const decrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(newCart);
  };

  const removeFromCart = (productId) => {
    const newCart = cart.filter((item) => item.product.id !== productId);
    setCart(newCart);
  };

  return (
    <div className={`cartModal ${isOpen ? "open" : ""}`}>
      <div className="cartModalContent">
        <div className="cartModalHeader">
          <button className="close-btn" onClick={onClose}>
            <FaTimes color="#ccc" />
          </button>
          <h5 className="mycart">My Cart</h5>
          <p className="mtcartlen"> {cart.length} Item(s)</p>
        </div>
        <div className="cartItemsWrapper">
          {cart.length === 0 ? (
            <div className="emptyCart">
              <h4 className="emptyCartH4">Your cart is currently empty.</h4>
              <iframe src="https://lottie.host/embed/aa738e8a-57bd-4d55-a452-99bc37cdd0e5/rFpQfBxcRM.json"></iframe>

              <Link to="/shop">
                <button className="continueShoppingButton" onClick={onClose}>
                  Go to Shop
                </button>
              </Link>
            </div>
          ) : (
            <>
              <div className="cartItemsContainer">
                {cart.map((item, index) => (
                  <div key={index} className="cartItem">
                    <img src={item.product.image} alt={item.product.name} />
                    <div>
                      <div className="productTop">
                        <p className="productName">{item.product.name}</p>
                        <p
                          className="removeButton"
                          onClick={() => removeFromCart(item.product.id)}
                        >
                          x
                        </p>
                      </div>

                      <div className="cartItemBottom">
                        <div className="quantityButtons">
                          <p
                            className="quantityButton"
                            onClick={() => decrementQuantity(item.product.id)}
                          >
                            -
                          </p>
                          <p className="quantity"> {item.quantity}</p>
                          <p
                            className="quantityButton"
                            onClick={() => incrementQuantity(item.product.id)}
                          >
                            +
                          </p>
                        </div>

                        <div>
                          <p className="productPrice">
                            $
                            {new Intl.NumberFormat("en-NG").format(
                              item.product.price * item.quantity
                            )}
                          </p>

                          <p className="selectedSize">
                            Size: {selectedSize[item.product.id] || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="buttomCart">
                  <span className="cartTotal">
                    <p className="subtotal">Subtotal:</p>
                    <p className="total">
                      {" "}
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>

                  <span className="shipping">
                    <p className="shiptext">Shipping: </p>
                    <p className="total">Calculated at checkout</p>
                  </span>

                  <span className="shipping">
                    <p className="shiptext">Total: </p>
                    <p className="total">
                      {" "}
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>
                </div>
                <div className="checkoutButton">
                  <Link to="/cart">
                    <p style={{ textDecoration: "none" }} onClick={onClose}>
                      Proceed to Checkout
                    </p>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const { cart } = useCart();
  const [toggle, setToggle] = useState(false);
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 760);
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
    setIsSideModalOpen(!isSideModalOpen);
  };

  const handleLinkClick = () => {
    if (toggle) {
      handleToggle();
    }
  };

  const handleCartClick = () => {
    setIsCartModalOpen(true);
  };

  const handleCartOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsCartModalOpen(false);
    }
  };

  return (
    <div className={`${toggle ? "activeNav" : "nav"}`}>
      <div className="mobileCon">
        {isMobile && (
          <>
            <button className="cart-btn" onClick={handleCartClick}>
              <FaCartPlus color="#500157" fontSize={25} />
              <span>{cart.length}</span>
            </button>
            <div>
              <Link to="/" className="dt">
                <span>Dont </span>Brag
              </Link>
            </div>
            <button className="nav-btn nav-close-btn" onClick={handleToggle}>
              {!toggle ? <FaBars fontSize={25} /> : <FaTimes fontSize={25} />}
            </button>
          </>
        )}
      </div>

      <img src={logo} alt="Logo" className="logo" />
      {!isMobile && (
        <ul className={`${toggle ? "list din open" : "list din"}`}>
          <li>
            <Link to="/" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/shop" onClick={handleLinkClick}>
              Shop
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={handleLinkClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              Contact
            </Link>
          </li>
        </ul>
      )}
      <div className={`${toggle ? "cartStyles open" : "cartStyles"}`}>
        <button
          onClick={handleCartClick}
          className={`${toggle ? "cartStyles open" : "cartStyles"}`}
        >
          CART: {cart.length}
        </button>
      </div>
      <div
        className={`overlay ${isSideModalOpen ? "open" : ""}`}
        onClick={handleToggle}
      ></div>
      <div
        className={`overlay ${isCartModalOpen ? "open" : ""}`}
        onClick={handleCartOverlayClick}
      ></div>
      <SideModal isOpen={isSideModalOpen} onClose={handleToggle} />
      <CartModal
        isOpen={isCartModalOpen}
        onClose={() => setIsCartModalOpen(false)}
      />
    </div>
  );
};

export default Navbar;
