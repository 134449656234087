import React from "react";
import Whatsapp from "./../assets/image/whatsapp.jpeg";
import "../styles/needHelp.css";

const NeedHelp = () => {
  const whatsappNumber = "+2348137816749";

  return (
    <div className="needHelp">
      <p>Need Help? Chat with us</p>
      <div className="needHelpTwo">
        <a
          href={`https://wa.me/${whatsappNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} alt="WhatsApp" />
        </a>
      </div>
    </div>
  );
};

export default NeedHelp;
