// ProductList.js
import React, { useEffect } from 'react';
import ProductItem from './ProductItem';
import { ProductWrapper } from './styles';
import Skeleton from 'react-loading-skeleton';

const ProductList = ({ data, setSelectedProduct, setShowModal, isLoading }) => {
  useEffect(() => {
    console.log('Product data received in ProductList:', data);
  }, [data]);

  return (
    <ProductWrapper>
      {isLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <div className="colPro" key={index}>
            <Skeleton width={304} height={275} />
            <h2 className="h2Name">
              <Skeleton width={200} />
            </h2>
            <div className="proPri">
              <p>
                <Skeleton width={100} />
              </p>
            </div>
          </div>
        ))
      ) : (
        data.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            setSelectedProduct={setSelectedProduct}
            setShowModal={setShowModal}
          />
        ))
      )}
    </ProductWrapper>
  );
};

export default ProductList;