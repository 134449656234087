import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [selectedSize, setSelectedSize] = useState({});

    const addToCart = (product, quantity, size) => {
        const existingItemIndex = cart.findIndex((item) => item.product.id === product.id);

        if (existingItemIndex !== -1) {
            // Product already exists in the cart, update its quantity and size
            const updatedCart = [...cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                quantity: updatedCart[existingItemIndex].quantity + quantity,
                selectedSize: size,
            };
            setCart(updatedCart);
            setSelectedSize((prevSelectedSize) => ({
                ...prevSelectedSize,
                [product.id]: size,
            }));
        } else {
            // Product doesn't exist in the cart, add it
            setCart((currentCart) => [
                ...currentCart,
                { product, quantity, selectedSize: size },
            ]);
            setSelectedSize((prevSelectedSize) => ({
                ...prevSelectedSize,
                [product.id]: size,
            }));
        }
    };

    const handleSizeSelect = (size, productId) => {
        setSelectedSize((prevSelectedSize) => ({
            ...prevSelectedSize,
            [productId]: size,
        }));
    };

    return (
        <CartContext.Provider value={{ cart, setCart, selectedSize, handleSizeSelect, addToCart }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};