import styled from "styled-components";
import { Card } from "react-bootstrap";

export const ProductContainer = styled.div`
  padding-top: 97px;
  margin: 0 48px;
`;

export const ProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 48px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5px;
    column-gap: 0px;
  }
`;

export const Text = styled.p`
  margin: 0;
`;

export const TextBold = styled.p`
  margin: 0;
  font-weight: 500;
`;

export const ProductCard = styled(Card)`
  width: 437px;
  height: 656px;
  border: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ProductImage = styled(Card.Img)`
  height: 584px;
  width: 437px;
  padding: 3px;
  transition: transform 0.3s ease-in-out;
  object-fit: contain;

  &:hover {
    transform: scale(1.05); // Scale up image by 10% on hover
  }
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const ProductName = styled(Card.Title)`
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: center;
`;

export const ProductItemBottom = styled.div`
  p:first-of-type {
    font-size: 12px;
    margin: 0px;
    text-align: center;
  }

  p:last-of-type {
    font-size: 10px;
    text-align: center;
    margin: 0px;
    text-align: center;
    font-weight: 800;
  }
`;

export const productNameShop = styled.div`
 text-align: center;
`;

export const ProductDescription = styled(Card.Text)`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

export const ProductPrice = styled(Card.Text)`
  font-size: 1.25rem;
  margin: 0.5rem 0;
  font-weight: bold;
`;

export const ProductDetails = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProductImageWrapper = styled.div`
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  /* gap: 20px; */
  justify-content: center;
  align-items: center;

  .original-image {
    width: 100%;
    transition: opacity 0.3s;
  }

  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* opacity: 0; // initially hidden */
    transition: opacity 0.3s;
  }

  .icon-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0;  */
    transition: opacity 0.3s;
  }

  &:hover {
    .original-image {
      opacity: 0;
    }

    .hover-image {
      opacity: 1;
    }

    .icon-wrapper {
      opacity: 1;
    }
  }
`;
