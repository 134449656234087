import React from "react";
import pr1 from "../assets/image/pr1.png";
import pr2 from "../assets/image/pr2.png";
import pr3 from "../assets/image/pr3.png";
import "../styles/view.css";

const View = () => {
  return (
    <div>
   
      <div className="view">
        <img src={pr1} alt="" className="vIM" />

        <div className="proDetails">
          <div className="imgOpt">
            <img src={pr1} alt="" />
            <img src={pr2} alt="" />
            <img src={pr3} alt="" />
          </div>

          <div className="deText">
            <div>
              <h2>Craze Jean</h2>
              <span>#6000</span>
              <p>
                These 'crazy jeans' are an absolute game-changer. The
                eye-catching design and bold details give my outfit an edgy and
                unforgettable flair. I love how they effortlessly turn heads and
                spark conversations wherever I go. They're not just jeans;
                they're a statement piece that lets me express my personality in
                a fashion-forward way. I'm obsessed with the creativity and
                daring style they bring to my look. A must-have for anyone who
                craves a touch of uniqueness in their fashion choices!
              </p>
              <select name="" id="">
                <option value="">Select Size</option>
                <option value="40">40</option>
                <option value="40">42</option>
                <option value="40">43</option>
              </select>
            </div>

            <div className="bCart">
              <button className="btn1">Add to Cart</button>
              <button className="btn2">Buy Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
