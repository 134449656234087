import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const cloudinaryCloudName = "dhqnrwxzv";
const uploadPreset = "qpa8opzy";

const EditProductModal = ({
  show,
  handleClose,
  product,
  handleEditConfirm,
  categories,
}) => {
  const [editedProduct, setEditedProduct] = useState(product || {});
  const [newSingleImage, setNewSingleImage] = useState(null);
  const [newSingleImagePreview, setNewSingleImagePreview] = useState(null);
  const [newMultipleImages, setNewMultipleImages] = useState([]);
  const [newMultipleImagesPreview, setNewMultipleImagesPreview] = useState([]);

  useEffect(() => {
    if (product && categories.length > 0) {
      const category = categories.find(c => c._id === product.category) || categories.find(c => c.name === product.category);
      if (category) {
        setEditedProduct({ ...product, category: category.name });
      } else {
        console.warn(`Category not found for product: ${product.name}`);
        setEditedProduct(product);
      }
    } else {
      setEditedProduct(product || {});
    }
    setNewSingleImage(null);
    setNewSingleImagePreview(null);
    setNewMultipleImages([]);
    setNewMultipleImagesPreview([]);
  }, [product, categories, show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'price' || name === 'stockInCount') {
      setEditedProduct({ ...editedProduct, [name]: parseFloat(value) });
    } else {
      setEditedProduct({ ...editedProduct, [name]: value });
    }
  };

  const handleSingleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewSingleImage(file);
      setNewSingleImagePreview(URL.createObjectURL(file));
    }
  };

  const handleMultipleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setNewMultipleImages([...newMultipleImages, ...files]);
    setNewMultipleImagesPreview([
      ...newMultipleImagesPreview,
      ...files.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const handleCloseModal = () => {
    setEditedProduct({});
    setNewSingleImage(null);
    setNewSingleImagePreview(null);
    setNewMultipleImages([]);
    setNewMultipleImagesPreview([]);
    handleClose();
  };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`,
        { method: "POST", body: formData }
      );
      const data = await response.json();
      if (response.ok) {
        return data.secure_url;
      } else {
        throw new Error(data.error.message);
      }
    } catch (error) {
      console.error("Cloudinary upload error:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    let updatedProduct = { ...editedProduct };

    try {
      if (newSingleImage) {
        const uploadedURL = await uploadToCloudinary(newSingleImage);
        updatedProduct.image = uploadedURL;
      }

      if (newMultipleImages.length) {
        const uploadedURLs = await Promise.all(
          newMultipleImages.map(uploadToCloudinary)
        );
        updatedProduct.images = [
          ...(updatedProduct.images || []),
          ...uploadedURLs,
        ];
      }

      // Ensure numeric values are sent as numbers
      updatedProduct.price = parseFloat(updatedProduct.price);
      updatedProduct.stockInCount = parseInt(updatedProduct.stockInCount, 10);

      console.log("Submitting updated product:", updatedProduct);
      handleEditConfirm(updatedProduct);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setEditedProduct({ ...editedProduct, [e.target.name]: e.target.checked });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = editedProduct.images.filter(
      (_, imgIndex) => imgIndex !== index
    );
    setEditedProduct({ ...editedProduct, images: updatedImages });
  };

  if (!product) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={editedProduct.name || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={editedProduct.description || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Rich Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="richDescription"
              value={editedProduct.richDescription || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control type="file" onChange={handleSingleImageChange} />
            {newSingleImagePreview ? (
              <img
                src={newSingleImagePreview}
                alt="New Product"
                style={{ width: "100px", marginTop: "10px" }}
              />
            ) : (
              editedProduct.image && (
                <img
                  src={editedProduct.image}
                  alt="Product"
                  style={{ width: "100px", marginTop: "10px" }}
                />
              )
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Images</Form.Label>
            <div>
              {editedProduct.images &&
                editedProduct.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Product ${index}`}
                      style={{ width: "100px", marginRight: "10px" }}
                    />
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteImage(index)}
                    >
                      Delete
                    </Button>
                  </div>
                ))}
              {newMultipleImagesPreview.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`New Product ${index}`}
                  style={{ width: "100px", marginRight: "10px" }}
                />
              ))}
              <Form.Control
                type="file"
                multiple
                onChange={handleMultipleImagesChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={editedProduct.price || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              type="text"
              name="brand"
              value={editedProduct.brand || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Stock Count</Form.Label>
            <Form.Control
              type="number"
              name="stockInCount"
              value={editedProduct.stockInCount || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              name="category"
              value={editedProduct.category || ""}
              onChange={handleChange}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formIsFeatured">
            <Form.Check
              type="checkbox"
              label="Featured"
              name="isFeatured"
              checked={editedProduct.isFeatured || false}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;