// ProductPage.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFeaturedProducts } from "../../redux/slice/featuredProductsSlice.js"; // Adjust the path as needed
import ProductList from "./ProductList.jsx";
import ProductModal from "./ProductModal.jsx";
import { Container, Row, Col, Form, Dropdown } from "react-bootstrap";

import styled from "styled-components";

const FilterSection = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

const FilterOption = styled.div`
  margin-bottom: 10px;
  padding: 0 5px;

  @media (max-width: 576px) {
    margin-bottom: 5px;
  }
`;

const SortSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SortDropdown = styled(Dropdown)`
  margin-left: 10px;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

const SortToggle = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: none;
  color: #000;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e9ecef;
  }

  &:focus {
    box-shadow: none;
  }

  @media (max-width: 576px) {
    width: 40%;
    justify-content: space-between;
  }
`;

const SortMenu = styled(Dropdown.Menu)`
  min-width: 120px;

  @media (max-width: 576px) {
    width: 50%;
  }
`;

const SortItem = styled(Dropdown.Item)`
  font-size: 14px;
  cursor: pointer;
`;

const ProductPage = () => {
  const dispatch = useDispatch();
  const { products: data, status } = useSelector(
    (state) => state.featuredProducts
  );
  const isLoading = status === "loading";

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filters, setFilters] = useState({
    category: "all",
    priceRange: "all",
    color: "all",
    size: "all",
  });
  const [sortOrder, setSortOrder] = useState("default");
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchFeaturedProducts());
    }
  }, [status, dispatch]);

  const applyFilters = (products) => {
    let filteredProducts = products;

    if (filters.category !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === filters.category
      );
    }

    if (filters.priceRange !== "all") {
      const [minPrice, maxPrice] = filters.priceRange.split("-");
      filteredProducts = filteredProducts.filter(
        (product) => product.price >= minPrice && product.price <= maxPrice
      );
    }

    if (filters.color !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) => product.color === filters.color
      );
    }

    if (filters.size !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) => product.size === filters.size
      );
    }

    return filteredProducts;
  };

  const applySorting = (products) => {
    let sortedProducts = [...products];

    if (sortOrder === "lowToHigh") {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "highToLow") {
      sortedProducts.sort((a, b) => b.price - a.price);
    }

    return sortedProducts;
  };

  const resetFilters = () => {
    setFilters({
      category: "all",
      priceRange: "all",
      color: "all",
      size: "all",
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const filteredProducts = applyFilters(data);
  const sortedProducts = applySorting(filteredProducts);

  return (
    <Container style={{ padding: 0 }}>
      <SortSection>
        <SortDropdown show={showFilters} onToggle={toggleFilters}>
          <SortToggle variant="light">Sort By</SortToggle>
          <SortMenu>
            <FilterSection>
              <Row>
                <Col xs={12} sm={6} md={3}>
                  <FilterOption>
                    <Form.Label>Category:</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.category}
                      onChange={(e) =>
                        setFilters({ ...filters, category: e.target.value })
                      }
                    >
                      <option value="all">Bags</option>
                      <option value="men">Tees</option>
                      <option value="women">Jean</option>
                      <option value="kids">Cap/hats</option>
                      <option value="shirts">Shirts</option>
                      <option value="socks">Socks</option>
                      <option value="underwear">Underwear's</option>
                      <option value="shoes">Shoes</option>
                    </Form.Control>
                  </FilterOption>
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <FilterOption>
                    <Form.Label>Price Range:</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.priceRange}
                      onChange={(e) =>
                        setFilters({ ...filters, priceRange: e.target.value })
                      }
                    >
                      <option value="all">All</option>
                      <option value="0-50">$0 - $50</option>
                      <option value="50-100">$50 - $100</option>
                      <option value="100-200">$100 - $200</option>
                      <option value="200-500">$200 - $500</option>
                      <option value="500-1000">$500 - $1000</option>
                    </Form.Control>
                  </FilterOption>
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <FilterOption>
                    <Form.Label>Color:</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.color}
                      onChange={(e) =>
                        setFilters({ ...filters, color: e.target.value })
                      }
                    >
                      <option value="all">All</option>
                      <option value="red">Red</option>
                      <option value="blue">Blue</option>
                      <option value="green">Green</option>
                      <option value="black">Black</option>
                      <option value="white">White</option>
                    </Form.Control>
                  </FilterOption>
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <FilterOption>
                    <Form.Label>Size:</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.size}
                      onChange={(e) =>
                        setFilters({ ...filters, size: e.target.value })
                      }
                    >
                      <option value="all">All</option>
                      <option value="xs">XS</option>
                      <option value="s">S</option>
                      <option value="m">M</option>
                      <option value="l">L</option>
                      <option value="xl">XL</option>
                    </Form.Control>
                  </FilterOption>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <button variant="secondary" onClick={resetFilters}>
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </FilterSection>
            <SortItem
              active={sortOrder === "default"}
              onClick={() => setSortOrder("default")}
            >
              Default
            </SortItem>
            <SortItem
              active={sortOrder === "lowToHigh"}
              onClick={() => setSortOrder("lowToHigh")}
            >
              Price: Low to High
            </SortItem>
            <SortItem
              active={sortOrder === "highToLow"}
              onClick={() => setSortOrder("highToLow")}
            >
              Price: High to Low
            </SortItem>
          </SortMenu>
        </SortDropdown>
      </SortSection>
      <ProductList
        data={sortedProducts}
        setSelectedProduct={setSelectedProduct}
        setShowModal={setShowModal}
        isLoading={isLoading}
      />
      {showModal && selectedProduct && (
        <ProductModal
          showModal={showModal}
          setShowModal={setShowModal}
          product={selectedProduct}
          size="lg"
        />
      )}
    </Container>
  );
};

export default ProductPage;
